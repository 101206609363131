@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@tailwind base;

/* :root {
  --primary: #dbd8d8ab;
  --secondary: #5BDBD3;
}

*::-webkit-scrollbar {
  width: 10px;
  
}

*::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 5px;
  padding: 5px;
}

*::-webkit-scrollbar-thumb {
  background: repeating-linear-gradient(
  45deg,
  var(--secondary),
  var(--secondary) 5px,
  var(--primary) 5px,
  var(--primary) 10px
);
background-clip: padding-box;
  border-radius: 5px;
  border: 1px solid var(--primary);
}  */

@tailwind components;
@tailwind utilities;

body,
html {
  font-family: "Jost", sans-serif;
  font-weight: 400;
  font-size: 16px;
}

input:focus,
textarea:focus,
select:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.sunColor {
  background: linear-gradient(#f9855d,#fdd288,white);
}

#container {
  width: 1500px;
  height: 1500px;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  overflow: hidden;
}

.wave-01 {
  position: absolute;
  width: 300%;
  height: 300%;
  left: -100%;
  top: 50%;
  background-color: rgba(0,190,255,0.4);
  border-radius: 45%;
  animation: rotate 10s linear infinite;
}


.wave-02 {
  position: absolute;
  width: 300%;
  height: 300%;
  left: -100%;
  top: 57%;
  background-color: rgba(0,70,110,0.4);
  border-radius: 43%;
  animation: rotate 7s linear infinite;
}

.wave-03 {
  position: absolute;
  width: 200%;
  height: 300%;
  left: -100%;
  top: 60%;
  background-color: rgba(0,90,110,0.4);
  border-radius: 40%;
  animation: rotate 5s linear infinite;
}
.sun {
  width: 150px;
  height: 150px;
  top: 20px;
  right: 40px;
  background-color: #f9cb5d;
  border-radius: 50%;
}

@keyframes rotate {
  100% { transform: rotate(360deg);}
}


.wrapper {
  width: 100%;
  margin: 0 auto;
  min-height: calc(100vh - 5rem);
  padding: 0 0.5rem;
}

/* dnd cards styles */
.list-cards {
  width: 315px;
  max-height: 83vh;
  margin-right: 0.5rem;
  border-radius: 5px;
}

.title-list {
  padding: 1rem 1rem 0;
}

.container-cards {
  overflow-x: auto;
  max-height: 75vh;
  padding: 0 1rem;
}

.card-container {
  overflow-y: hidden;
  margin: 0.5rem 0;
}

.input-title {
  width: 100%;
  height: 1.7rem;
  font-size: 1.2rem;
  font-weight: bold;
  border: none;
}

.input-title:focus {
  background-color: #ddd;
}

.data-table-extensions {
  z-index: 10;
  position: absolute;
  right: 0;
  background: white;
  width: 72px !important;
  border-radius: 50px;
  padding: 0 !important;
  margin-right: 10px;
}

.editable-title-container {
  position: relative;
  display: flex;
}

.editable-title {
  height: 1.7rem;
  flex-grow: 1;
  font-size: 1.2rem;
  font-weight: bold;
}

.list-button {
  height: 1.5rem;
  border: none;
  cursor: pointer;
}

.menu-card {
  position: absolute;
  right: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  z-index: 999;
  list-style-type: none;
  cursor: default;
}

/* Multi upload styles */
.image_upload_multi {
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  background-color: rgb(218, 214, 214);
}

/* OTP Container */
.react-tel-input .form-control {
  @apply !w-full py-6 !text-base;
}

.otp-container {
  @apply flex justify-between gap-2;
}

.otp-container input {
  @apply !mr-0 flex-1 py-5 outline-none border-yellow-600 rounded-md border;
}

/* Basic class for tailwind */
.linear-bg {
  background: linear-gradient(269.82deg, #07091c00 0.17%, #080a1ad6 99.87%);
}
.curve-linear-bg {
  background: linear-gradient(174.48deg, #000000 43.25%, #cc0000 88.39%);
}
.hoveres {
  @apply hidden;
}

.hovered:hover .hoveres {
  @apply flex-colo;
}

.flex-colo {
  @apply flex flex-col justify-center items-center;
}
.flex-rows {
  @apply flex flex-row justify-center items-center;
}
.flex-btn {
  @apply flex justify-between items-center;
}
.transitions {
  @apply transition duration-300 ease-in;
}
.zIndex {
  z-index: 1000;
}

/* rc-drawer CSS */

.rc-drawer {
  overflow: visible;
}

.rc-drawer .rc-drawer-content-wrapper {
  width: 100%;
}

.rc-drawer {
  position: fixed;
  transition: width 0s ease 0.3s, height 0s ease 0.3s,
    -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: width 0s ease 0.3s, height 0s ease 0.3s,
    transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: width 0s ease 0.3s, height 0s ease 0.3s,
    transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  z-index: 9999;
}

.rc-drawer > \* {
  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    box-shadow 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    box-shadow 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    box-shadow 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.rc-drawer.rc-drawer-open {
  transition: -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.rc-drawer .rc-drawer-mask {
  background: #000;
  height: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    height 0s ease 0.3s;
  width: 100%;
}

.rc-drawer-content-wrapper {
  background: #fff;
  position: absolute;
}

.rc-drawer-content {
  overflow: auto;
  position: relative;
  z-index: 1;
}

.rc-drawer-handle {
  align-items: center;
  background: #fff;
  cursor: pointer;
  display: none;
  font-size: 16px;
  height: 40px;
  justify-content: center;
  line-height: 40px;
  position: absolute;
  text-align: center;
  top: 72px;
  width: 41px;
  z-index: 0;
}

.rc-drawer-handle-icon {
  background: #333;
  height: 2px;
  position: relative;
  transition: background 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  width: 14px;
}

.rc-drawer-handle-icon:after,
.rc-drawer-handle-icon:before {
  background: #333;
  content: "";
  display: block;
  height: 2px;
  position: absolute;
  transition: -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  width: 100%;
}

.rc-drawer-handle-icon:before {
  top: -5px;
}

.rc-drawer-handle-icon:after {
  top: 5px;
}

.rc-drawer-left,
.rc-drawer-right {
  height: 100%;
  width: 0;
}

.rc-drawer-left .rc-drawer-content,
.rc-drawer-left .rc-drawer-content-wrapper,
.rc-drawer-right .rc-drawer-content,
.rc-drawer-right .rc-drawer-content-wrapper {
  height: 100%;
}

.rc-drawer-left.rc-drawer-open,
.rc-drawer-right.rc-drawer-open {
  width: 100%;
}

.rc-drawer-left.rc-drawer-open.no-mask,
.rc-drawer-right.rc-drawer-open.no-mask {
  width: 0;
}

.rc-drawer-left {
  left: 0;
  top: 0;
}

.rc-drawer-left .rc-drawer-handle {
  border-radius: 0 4px 4px 0;
  right: -40px;
}

.rc-drawer-left .rc-drawer-handle,
.rc-drawer-left.rc-drawer-open .rc-drawer-content-wrapper {
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
}

.rc-drawer-right {
  right: 0;
  top: 0;
}

.rc-drawer-right .rc-drawer-content-wrapper {
  right: 0;
}

.rc-drawer-right .rc-drawer-handle {
  border-radius: 4px 0 0 4px;
  left: -40px;
}

.rc-drawer-right .rc-drawer-handle,
.rc-drawer-right.rc-drawer-open .rc-drawer-content-wrapper {
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
}

.rc-drawer-right.rc-drawer-open.no-mask {
  right: 1px;
  -webkit-transform: translateX(1px);
  transform: translateX(1px);
}

.rc-drawer-bottom,
.rc-drawer-top {
  height: 0;
  width: 100%;
}

.rc-drawer-bottom .rc-drawer-content,
.rc-drawer-bottom .rc-drawer-content-wrapper,
.rc-drawer-top .rc-drawer-content,
.rc-drawer-top .rc-drawer-content-wrapper {
  width: 100%;
}

.rc-drawer-bottom .rc-drawer-content,
.rc-drawer-bottom.rc-drawer-open,
.rc-drawer-top .rc-drawer-content,
.rc-drawer-top.rc-drawer-open {
  height: 100%;
}

.rc-drawer-bottom.rc-drawer-open.no-mask,
.rc-drawer-top.rc-drawer-open.no-mask {
  height: 0;
}

.rc-drawer-bottom .rc-drawer-handle,
.rc-drawer-top .rc-drawer-handle {
  left: 50%;
  margin-left: -20px;
}

.rc-drawer-top {
  left: 0;
  top: 0;
}

.rc-drawer-top .rc-drawer-handle {
  border-radius: 0 0 4px 4px;
  bottom: -40px;
  top: auto;
}

.rc-drawer-top .rc-drawer-handle,
.rc-drawer-top.rc-drawer-open .rc-drawer-content-wrapper {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.rc-drawer-bottom {
  bottom: 0;
  left: 0;
}

.rc-drawer-bottom .rc-drawer-content-wrapper {
  bottom: 0;
}

.rc-drawer-bottom .rc-drawer-handle {
  border-radius: 4px 4px 0 0;
  top: -40px;
}

.rc-drawer-bottom .rc-drawer-handle,
.rc-drawer-bottom.rc-drawer-open .rc-drawer-content-wrapper {
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
}

.rc-drawer-bottom.rc-drawer-open.no-mask {
  bottom: 1px;
  -webkit-transform: translateY(1px);
  transform: translateY(1px);
}

.rc-drawer.rc-drawer-open .rc-drawer-mask {
  height: 100%;
  opacity: 0.3;
  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.rc-drawer.rc-drawer-open .rc-drawer-handle-icon {
  background: transparent;
}

.rc-drawer.rc-drawer-open .rc-drawer-handle-icon:before {
  -webkit-transform: translateY(5px) rotate(45deg);
  transform: translateY(5px) rotate(45deg);
}

.rc-drawer.rc-drawer-open .rc-drawer-handle-icon:after {
  -webkit-transform: translateY(-5px) rotate(-45deg);
  transform: translateY(-5px) rotate(-45deg);
}
